import React from 'react'
import Header from '../../Typography/Header'
import Regular from '../../Typography/Regular'
import DrawerSideBar from '../Drawer';

import {InboxIcon, BellIcon} from "../../../contants/index";

// import { useSelector, useDispatch } from "react-redux";
// import { selectLayout, sideBarStatus } from "../../../redux/Layout/layoutSlice";


// import { BiMenu } from "react-icons/bi";

const PageHeader = ({ name, description , dashboard = false , dashboardType = null , setButton = null  }) => {

  // const dispatch = useDispatch();
  // const business = useSelector(selectLayout);

  // const handleButton = () => {
  //   let data = business.sideBarValue ? false : true;
  //   dispatch( sideBarStatus(data) );
  // }

  return (
    <div className='w-full flex justify-between'>
          
          {/* <div className='flex flex-col max-[1300px]:hidden'>
            
            <Header>{name}</Header>
            <span className={`text-regularText my-2`}><Regular>{description}</Regular></span>

          </div> */}

          {/* <div className='hidden max-[1300px]:flex max-[1300px]:flex-row max-[1300px]:gap-2 '> */}
          <div className='flex flex-row gap-2 mt-4'>

            {/* <span className='cursor-pointer' onClick={ () => handleButton() } style={{ fontSize:23}}><BiMenu/></span> */}
            
            <div className='min-[1301px]:hidden'>
                <DrawerSideBar/>
            </div>
     
            <div className='flex flex-row gap-3'>

              <div className='flex flex-col'>
                <Header>{name}</Header>
                <span className={`text-regularText my-2`}><Regular>{description}</Regular></span>
              
              </div>

              { dashboard &&  

              <div className='flex flex-row gap-3'>
                
                <Regular>
                  <span onClick={() => setButton('home') } className={ dashboardType === 'home' ? `cursor-pointer bg-mainText text-white px-3 py-2 rounded-lg text-xs` : `cursor-pointer text-mainText text-sm`}>Home</span>
                </Regular>

                <Regular>
                  <span onClick={() => setButton('estimatics') }  className={ dashboardType === 'estimatics' ? `cursor-pointer bg-mainText text-white px-3 py-2 rounded-lg text-xs` : `cursor-pointer text-mainText text-sm`}>Estimatics</span>
                </Regular>

              </div> }

            </div>


          </div>

           <div className='flex flex-row gap-4 mt-4'>

            <div style={{ width:40, height:40 }} className='bg-white flex justify-center items-center rounded-full border border-zinc-300 cursor-pointer'>
                <img src={InboxIcon} alt='icon'/>
            </div>

            <div style={{ width:40, height:40 }} className='bg-white flex justify-center items-center rounded-full border border-zinc-300 cursor-pointer'>
                <img src={BellIcon} alt='icon'/>
            </div>
   
          </div>
    </div>
  
  )
}

export default PageHeader
