// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#mapImage {
    max-width : 1078px ;
    max-height : 743px;
}

@media only screen and (max-width: 1499px) {
    #mapImage {
        max-width : 878px ;
        max-height : 620px;
    }
}

@media only screen and (max-width: 1230px) {
    #mapImage {
        max-width : 878px ;
        max-height : 450px;
    }
}

@media only screen and (max-width: 1030px) {
    #mapImage {
        max-width : 478px ;
        max-height : 350px;
    }
}

@media only screen and (max-width: 913px) {
    #mapImage {
        max-width : 678px ;
        max-height : 550px;
        margin-bottom: 10%;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/App/Franchise/css/style.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI;QACI,kBAAkB;QAClB,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,kBAAkB;QAClB,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,kBAAkB;QAClB,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,kBAAkB;QAClB,kBAAkB;QAClB,kBAAkB;IACtB;AACJ","sourcesContent":["#mapImage {\n    max-width : 1078px ;\n    max-height : 743px;\n}\n\n@media only screen and (max-width: 1499px) {\n    #mapImage {\n        max-width : 878px ;\n        max-height : 620px;\n    }\n}\n\n@media only screen and (max-width: 1230px) {\n    #mapImage {\n        max-width : 878px ;\n        max-height : 450px;\n    }\n}\n\n@media only screen and (max-width: 1030px) {\n    #mapImage {\n        max-width : 478px ;\n        max-height : 350px;\n    }\n}\n\n@media only screen and (max-width: 913px) {\n    #mapImage {\n        max-width : 678px ;\n        max-height : 550px;\n        margin-bottom: 10%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
