import React from 'react'

const CalendarWeekendLayout = ({ item , index  , handleWeekData , selectedWeekDateIndex  }) => {
  // onClick={ () =>  handleWeekData( index ) }
  return (
    <div className='w-full flex flex-col border px-3 bg-gray-200 py-2 border-gray-300' >
        <span className='text-sm text-black font-bold'>{item?.day}</span>
    </div>
  )
}

export default CalendarWeekendLayout;