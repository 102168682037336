import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIServices } from "../../hooks/fetchAPI";
import ObjectDeserializer from "../../utils/object-to-params";

export const getFilteredInsurance = createAsyncThunk(
    'get/data/insurance',
    async (value, thunkApi) => {
        try {
            const { objectToParams } = ObjectDeserializer;

            const response = await APIServices.get({ url: `/insurance/list/all` });

            return response.data.data;
        } catch (error) {
            return thunkApi.rejectWithValue({ error: error.data, status: error.status });
        }
    }
)