import React from 'react'

const ErrorPage = () => {
  return (
    <div className='flex justify-center items-center h-screen'>
        <h1 className='text-4xl font-bold tracking-wider'>404 Page not found.</h1>
    </div>
  )
}

export default ErrorPage
