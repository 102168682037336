import React from 'react'

const CalendarWeekdaysLayout = ({ day }) => {
  return (
    <div className='w-full flex flex-col border px-3 bg-white py-2'>
        <span className='text-sm text-black font-bold'>{ day }</span>

        <div className='w-full my-5 flex flex-col justify-center items-center gap'>
        <span className='text-sm text-black font-semibold'>23 Schedule Repairs</span>
        <span className='text-xs text-black'>Total: $120,000.00</span>
        </div>

    </div>
  )
}


export default CalendarWeekdaysLayout;
