// Threedom Color Pallete

export const PrimaryColor = "#66BE7A";
export const SecondaryColor = "#0098F9";
export const TertiaryColor = "#DE4C50";

// Random Shades
export const ShadePrimary = "#66BE7A";
export const ShadeText = "#001800";
export const ShadePlaceHolder = "#838383";
export const ShadeAccentColor = "#003800";